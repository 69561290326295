import React, { useEffect, lazy, Suspense} from "react";
import { Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { auth } from "./firebase";
import { useDispatch } from "react-redux";
import { currentUser } from "./functions/auth";
import { LoadingOutlined } from "@ant-design/icons";


// import Login from "./pages/auth/Login";
// import Register from "./pages/auth/Register";
// import Home from "./pages/Home";
// import Footer from "./components/nav/Footer";
// import Header from "./components/nav/Header";
// import AboutUs from "./pages/AboutUs"
// import RegisterComplete from "./pages/auth/RegisterComplete";
// import ForgotPassword from "./pages/auth/ForgotPassword";
// import History from "./pages/user/History";
// import UserRoute from "./components/routes/UserRoute";
// import AdminRoute from "./components/routes/AdminRoute";
// import Password from "./pages/user/Password";
// import Wishlist from "./pages/user/Wishlist";
// import AdminDashboard from "./pages/admin/AdminDashboard";
// import CategoryCreate from "./pages/admin/category/CategoryCreate";
// import CategoryUpdate from "./pages/admin/category/CategoryUpdate";
// import SubCreate from "./pages/admin/sub/SubCreate";
// import SubUpdate from "./pages/admin/sub/SubUpdate";
// import ProductCreate from "./pages/admin/product/ProductCreate";
// import AllProducts from "./pages/admin/product/AllProducts";
// import ProductUpdate from "./pages/admin/product/ProductUpdate";
// import Product from './pages/Product'
// import CategoryHome from './pages/category/CategoryHome'
// import SubHome from './pages/sub/SubHome'
// import Shop from './pages/Shop'
// import Cart from './pages/Cart'
// import SideDrawer from './components/drawer/SideDrawer'
// import NavDrawer from './components/drawer/NavDrawer'
// import Checkout from './pages/Checkout'
// import CreateCouponPage from "./pages/admin/coupon/CreateCouponPage";
// import Payment from "./pages/Payment";
// import ThankYou from "./pages/ThankYou";


const Login = lazy(() => import("./pages/auth/Login"));
const Customer = lazy(() => import("./pages/auth/Customer"));
const Register = lazy(() => import("./pages/auth/Register"));
const Home = lazy(() => import("./pages/Home"));
const Footer =lazy(() => import("./components/nav/Footer"));
const Header =lazy(() => import("./components/nav/Header"));
const AboutUs =lazy(() => import("./pages/AboutUs"))
const RegisterComplete =lazy(() => import("./pages/auth/RegisterComplete"));
const ForgotPassword =lazy(() => import("./pages/auth/ForgotPassword"));
const History =lazy(() => import("./pages/user/History"));
const UserRoute =lazy(() => import("./components/routes/UserRoute"));
const AdminRoute =lazy(() => import("./components/routes/AdminRoute"));
const Password =lazy(() => import("./pages/user/Password"));
const Wishlist =lazy(() => import("./pages/user/Wishlist"));
const AdminDashboard = lazy(() => import("./pages/admin/AdminDashboard"));
const CategoryCreate =lazy(() => import("./pages/admin/category/CategoryCreate"));
const CategoryUpdate =lazy(() => import("./pages/admin/category/CategoryUpdate"));
const SubCreate =lazy(() => import("./pages/admin/sub/SubCreate"));
const SubUpdate =lazy(() => import("./pages/admin/sub/SubUpdate"));
const ProductCreate =lazy(() => import("./pages/admin/product/ProductCreate"));
const AllProducts =lazy(() => import("./pages/admin/product/AllProducts"));
const CustomerList =lazy(() => import("./pages/admin/customer/CustomerList"));
const CustomerDetails =lazy(() => import("./pages/admin/customer/CustomerDetails"));
const CustomerUpdate =lazy(() => import("./pages/admin/customer/CustomerUpdate"));
const ProductUpdate =lazy(() => import("./pages/admin/product/ProductUpdate"));
const Product =lazy(() => import('./pages/Product'))
const CategoryHome =lazy(() => import('./pages/category/CategoryHome'))
const SubHome =lazy(() => import('./pages/sub/SubHome'))
const Shop =lazy(() => import('./pages/Shop'))
const Cart =lazy(() => import('./pages/Cart'))
const SideDrawer =lazy(() => import('./components/drawer/SideDrawer'))
const NavDrawer =lazy(() => import('./components/drawer/NavDrawer'))
const Checkout =lazy(() => import('./pages/Checkout'))
const CreateCouponPage =lazy(() => import("./pages/admin/coupon/CreateCouponPage"));
const Payment =lazy(() => import("./pages/Payment"));
const ThankYou =lazy(() => import("./pages/ThankYou"));
const RegistrationRedirect = lazy(() => import("./pages/auth/RegistrationRedirect"));



const App = () => {
  const dispatch = useDispatch();

  // to check firebase auth state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        // console.log("user", user);

        currentUser(idTokenResult.token)
          .then((res) => {
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                email: res.data.email,
                name: res.data.name,
                firstName: res.data.firstName,
                lastName: res.data.lastName,
                address: res.data.address,
                city: res.data.city,
                state: res.data.state,
                zipCode: res.data.zipCode,
                business: res.data.business,
                phone: res.data.phone,
                token: idTokenResult.token,
                role: res.data.role,
                _id: res.data._id,
              },
            });
          })
          .catch((err) => console.log(err));
      }
    });
    // cleanup
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <Suspense
    fallback={
      <div className="col text-center p-5">
        __Ermi
        <LoadingOutlined />
        Gebeya__
      </div>
    }
  >
  
      <NavDrawer />
      <Header />
      <SideDrawer />
      <ToastContainer />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/registration" component={Customer} />
        <Route exact path="/register/complete" component={RegisterComplete} />
        <Route exact path="/forgot/password" component={ForgotPassword} />
        <UserRoute exact path="/user/history" component={History} />
        <UserRoute exact path="/user/password" component={Password} />
        <UserRoute exact path="/user/wishlist" component={Wishlist} />
        <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} />
        <AdminRoute exact path="/admin/category" component={CategoryCreate} />
        <AdminRoute
          exact
          path="/admin/category/:slug"
          component={CategoryUpdate}
        />
        <AdminRoute exact path="/admin/sub" component={SubCreate} />
        <AdminRoute exact path="/admin/customers" component={CustomerList} />
        <AdminRoute exact path="/admin/customer/:slug" component={CustomerDetails} />
        <AdminRoute exact path="/admin/customer-update/:slug" component={CustomerUpdate} />
        <AdminRoute exact path="/admin/sub/:slug" component={SubUpdate} />
        <AdminRoute exact path="/admin/product" component={ProductCreate} />
        <AdminRoute exact path="/admin/products" component={AllProducts} />
        <AdminRoute exact path="/admin/product/:slug" component={ProductUpdate} />

        <Route exact path="/product/:slug" component={Product} />
        <Route exact path="/category/:slug" component={CategoryHome} />
        <Route exact path="/sub/:slug" component={SubHome} />
        <Route exact path="/shop/" component={Shop} />
        <UserRoute exact path="/user/cart" component={Cart} />
        <UserRoute exact path="/user/checkout" component={Checkout} />
        <AdminRoute exact path="/admin/coupon" component={CreateCouponPage} />
        <UserRoute exact path="/payment" component={Payment} />
        <UserRoute exact path="/user/thank-you" component={ThankYou} />
        <Route exact path="/registration-complete" component={RegistrationRedirect} />
      </Switch>
      <Footer />
      </Suspense>
  );
};

export default App;
