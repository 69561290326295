import firebase from "firebase/app";
import "firebase/auth";
// firebase config
const config = {
  apiKey: "AIzaSyCnFCI8talWB0u7M63kVBxtpVpDB9Os_1I",
  authDomain: "ecom-projects-92ce3.firebaseapp.com",
  projectId: "ecom-projects-92ce3",
  storageBucket: "ecom-projects-92ce3.appspot.com",
  messagingSenderId: "368575680359",
  appId: "1:368575680359:web:ec5a00a719eaf4cfe8a9c2"
};
// initialize firebase app
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
// export
// export default firebase;
export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

